export default [
    {
        nama_industri:'PT. Kaltim Industrial Estate',
        id_industri:'5f4751ba6eb6001c3fc8a754',
        jenis_industri:'Kawasan Industri',
        provinsi:'Kalimantan Timur',
        kabkot:'Kota Bontang',
        alamat:'Komplek Kawasan Industri PT. KIE, Jl. Paku Aji Kav. 79 Kelurahan Guntung, Kecamatan Bontang Utara Kota Bontang Provinsi Kalimantan Timur',
        kontak:"Viorentin Gadis Nucifera / 081254922923",
        status:'Pengoperasian',
        waktu:'02/10/2022, 06:00',
        "id": "5f4751ba6eb6001c3fc8a754",
        "detail": {
            "compName": "PT. Kaltim Industrial Estate",
            "compType": "Kawasan Industri",
            "compAddress": "Komplek Kawasan Industri PT. KIE, Jl. Paku Aji Kav. 79 Kelurahan Guntung, Kecamatan Bontang Utara Kota Bontang Provinsi Kalimantan Timur",
            "compCP": "Viorentin Gadis Nucifera / 081254922923",
            "tahap": "Pengoperasian",
            "compProvince": "5deda903b267f9c9e60d3216",
            "compCity": "5dee059db267f9c9e60eb17c",
            "compPhone": "0548 - 41368",
            "compMail": "vyo013@gmail.com",
            "personPhone": "081254922923",
            "personMail": "vyo013@gmail.com",
            "updatedAt": 1628474028,
            "createdAt": 1598509498,
            "compProvinceName": "Kalimantan Timur",
            "compCityName": "Kota Bontang"
        },
        "fileTender": {
            "label": "",
            "path": "http://203.166.207.51//storage/2021-08-09T01:52:42.352Za. SPK SPARING KIE_HAS Agustus 2020.pdf",
            "upload": 2,
            "progress": 100
        },
        "fileVendor": {
            "label": "",
            "path": "http://203.166.207.51/storage/2020-10-27T00:53:47.181ZSPK SPARING KIE dgn HAS.pdf",
            "upload": 2,
            "progress": 100
        },
        "fileJadwal": {
            "label": "",
            "path": "http://203.166.207.51/storage/2020-10-27T00:50:40.947ZSurat Pemberitahuan Penundaan Pekerjaan Pemasangan Alat Sparing.pdf",
            "upload": 2,
            "progress": 100
        },
        "photoPemasangan": {
            "label": "",
            "path": "http://203.166.207.51//storage/2021-08-09T01:53:02.231Zb. Foto Instalasi.jpg",
            "upload": 2,
            "progress": 100
        },
        "photoSPARING": {
            "label": "",
            "path": "http://203.166.207.51//storage/2021-08-09T01:53:08.938Zc. Foto Sparing.jpg",
            "upload": 2,
            "progress": 100
        },
        "filePemasangan": {
            "label": "",
            "path": "http://203.166.207.51//storage/2021-08-09T01:53:20.035Zd. Dokumen Pemasangan.pdf",
            "upload": 2,
            "progress": 100
        },
        "fileCommission": {
            "label": "",
            "path": "http://203.166.207.51//storage/2021-08-09T01:53:32.332Ze. Jadwal Perencanaan Commissioning SPARING PT. KIE.pdf",
            "upload": 2,
            "progress": 100
        },
        "filePengoperasion": {
            "label": "",
            "path": "http://203.166.207.51//storage/2021-08-09T01:53:38.095Zf. Jadwal Pengoperasian SPARING KIE PT. KIE.pdf",
            "upload": 2,
            "progress": 100
        },
        "__v": 0
    },
    {
        nama_industri:'PT Kitadin Embalut',
        id_industri:'5f484d9a6eb6001c3fc8a75d',
        jenis_industri:'Pertambangan Batu Bara',
        provinsi:'Kalimantan Timur',
        kabkot:'Kabupaten Kutai Kartanegara',
        alamat:'Desa Embalut, Kecamatan Tenggarong Seberang Kabupaten Kutai Kertanegara, Kalimantan Timur 75572',
        kontak:"Muhammad Ngirom Hudi, email: muh_h@banpuindo.co.id",
        status:'Pemasangan',
        waktu:'02/10/2022, 06:00',
        "id": "5f484d9a6eb6001c3fc8a75d",
        "detail": {
            "compName": "PT Kitadin Embalut",
            "compType": "Pertambangan Batu Bara",
            "compAddress": "Desa Embalut, Kecamatan Tenggarong Seberang Kabupaten Kutai Kertanegara, Kalimantan Timur 75572",
            "compCP": "Muhammad Ngirom Hudi, email: muh_h@banpuindo.co.id",
            "tahap": "Pemasangan",
            "compProvince": "5deda903b267f9c9e60d3216",
            "compCity": "5dee1920b267f9c9e60f02d5",
            "compPhone": "0",
            "compMail": "muh_h@banpuindo.co.id",
            "personMail": "muh_h@banpuindo.co.id",
            "personPhone": "0",
            "updatedAt": 1601364748,
            "createdAt": 1598573978,
            "compProvinceName": "Kalimantan Timur",
            "compCityName": "Kabupaten Kutai Kartanegara"
        },
        "fileTender": {
            "label": "",
            "path": "http://203.166.207.51/storage/2020-09-29T07:31:58.351Z1. Bukti Pengadaan Sparing (TENDER)2.pdf",
            "upload": 2,
            "progress": 100
        },
        "fileVendor": {
            "label": "",
            "path": "http://203.166.207.51/storage/2020-09-29T07:32:06.166Z2. KTD PO Envipro.pdf",
            "upload": 2,
            "progress": 100
        },
        "fileJadwal": {
            "label": "",
            "path": "http://203.166.207.51/storage/2020-09-29T07:32:17.093Z3. Surat Pernyataan Pengadaan ENVIPRO_Schedule KTD R2.pdf",
            "upload": 2,
            "progress": 100
        },
        "photoPemasangan": {
            "label": "",
            "path": "",
            "upload": 0
        },
        "photoSPARING": {
            "label": "",
            "path": "",
            "upload": 0
        },
        "filePemasangan": {
            "label": "",
            "path": "",
            "upload": 0
        },
        "fileCommission": {
            "label": "",
            "path": "",
            "upload": 0
        },
        "filePengoperasion": {
            "label": "",
            "path": "",
            "upload": 0
        },
        "__v": 0
    },
];
