<template>
  <div>
    <base-header class="pb-6">
      <b-row class="py-4">
        <b-col lg="6" cols="7">
          <h6 class="h2 text-white d-inline-block mb-0">Pelaporan Progress View</h6>
        </b-col>
        <b-col lg="6" cols="7" style="display: flex; justify-content: flex-end">
          <base-button @click="back()">Kembali</base-button>
        </b-col>
      </b-row>
    </base-header>

    <div class="container-fluid mt--6">
      <div class="row justify-content-center mb--4">
        <div class="col">
          <div id="umum" class="card">
            <div class="card-header d-flex justify-content-between">
              <h3 class="mb-0">Bukti Pemasangan SPARING Terkait COVID-19</h3>
            </div>

            <div v-if="listData.length == 0">
              <h1>Loading ...</h1>
            </div>
            <div v-else class="card-body">
              <div class="row mb-4">
                <div class="col-lg-2">
                  <strong>Nama Perusahaan</strong>
                </div>
                <div class="col-lg-10">
                  <span>{{ listData[0].detail.compName }}</span>
                </div>
              </div>
              <div class="row mb-4">
                <div class="col-lg-2">
                  <strong>Jenis Industri</strong>
                </div>
                <div class="col-lg-10">
                  <span>{{ listData[0].detail.compType }}</span>
                </div>
              </div>
              <div class="row mb-4">
                <div class="col-lg-2">
                  <strong>Alamat Perusahaan</strong>
                </div>
                <div class="col-lg-10">
                  <span>{{ listData[0].detail.compAddress }}</span>
                </div>
              </div>
              <div class="row mb-4">
                <div class="col-lg-2">
                  <strong>Provinsi</strong>
                </div>
                <div class="col-lg-10">
                  <span>{{ listData[0].detail.compProvinceName }}</span>
                </div>
              </div>
              <div class="row mb-4">
                <div class="col-lg-2">
                  <strong>Kabupaten / Kota</strong>
                </div>
                <div class="col-lg-10">
                  <span>{{ listData[0].detail.compCityName }}</span>
                </div>
              </div>
              <div class="row mb-4">
                <div class="col-lg-2">
                  <strong>Telepon Kantor</strong>
                </div>
                <div class="col-lg-10">
                  <span>{{ listData[0].detail.compPhone }}</span>
                </div>
              </div>
              <div class="row mb-4">
                <div class="col-lg-2">
                  <strong>Email Kantor</strong>
                </div>
                <div class="col-lg-10">
                  <span>{{ listData[0].detail.compMail }}</span>
                </div>
              </div>
              <div class="row mb-4">
                <div class="col-lg-2">
                  <strong>Penanggungjawab Sparing</strong>
                </div>
                <div class="col-lg-10">
                  <span>{{ listData[0].detail.compCP }}</span>
                </div>
              </div>
              <div class="row mb-4">
                <div class="col-lg-2">
                  <strong>Handphone Penanggungjawab Sparing</strong>
                </div>
                <div class="col-lg-10">
                  <span>{{ listData[0].detail.personPhone }}</span>
                </div>
              </div>
              <div class="row mb-4">
                <div class="col-lg-2">
                  <strong>Email Penanggungjawab Sparing</strong>
                </div>
                <div class="col-lg-10">
                  <span>{{ listData[0].detail.personMail }}</span>
                </div>
              </div>
              <div class="row mb-4">
                <div class="col-lg-2">
                  <strong>Tahapan Pemasangan SPARING</strong>
                </div>
                <div class="col-lg-10">
                  <span>{{ listData[0].detail.tahap }}</span>
                </div>
              </div>
              <div class="row mb-4">
                <div class="col-lg-2">
                  <strong>Bukti Pengadaan Sparing (Dokumen Tender)</strong>
                </div>
                <div class="col-lg-10">
                  <a :href="listData[0].fileTender.path" target="blank">
                    <span>
                      <i class="ni ni-cloud-download-95 text-primary"></i>
                    </span>
                    <span>
                      {{
                        listData[0].fileTender.path === "" ||
                        listData[0].fileTender.path === undefined ||
                        listData[0].fileTender.path === null
                          ? " Belum ada File Terupload"
                          : "File Terupload"
                      }}
                    </span>
                  </a>
                </div>
              </div>
              <div>
              <div v-if="listData[0].detail.tahap === 'Pemasangan'">
                <div class="row mb-4">
                  <div class="col-lg-2">
                    <strong>Pemenang Sparing</strong>
                  </div>
                  <div class="col-lg-10">
                    <a :href="listData[0].fileVendor.path" target="blank">
                      <span>
                        <i class="ni ni-cloud-download-95 text-primary"></i>
                      </span>
                      <span>
                        {{
                          listData[0].fileVendor.path === "" ||
                          listData[0].fileVendor.path === undefined ||
                          listData[0].fileVendor.path === null
                            ? " Belum ada File Terupload"
                            : "File Terupload"
                        }}
                      </span>
                    </a>
                  </div>
                </div>
                <div class="row mb-4">
                  <div class="col-lg-2">
                    <strong>Jadwal Pemasangan Sparing</strong>
                  </div>
                  <div class="col-lg-10">
                    <a :href="listData[0].fileJadwal.path" target="blank">
                      <span>
                        <i class="ni ni-cloud-download-95 text-primary"></i>
                      </span>
                      <span>
                        {{
                          listData[0].fileJadwal.path === "" ||
                          listData[0].fileJadwal.path === undefined ||
                          listData[0].fileJadwal.path === null
                            ? " Belum ada File Terupload"
                            : "File Terupload"
                        }}
                      </span>
                    </a>
                  </div>
                </div>
              </div>
              </div>
              <div v-if="listData[0].detail.tahap === 'Pengoperasian'">
                <div class="row mb-4">
                  <div class="col-lg-2">
                    <strong>Foto Instalasi</strong>
                  </div>
                  <div class="col-lg-10">
                    <a :href="listData[0].photoPemasangan.path" target="blank">
                      <span>
                        <i class="ni ni-cloud-download-95 text-primary"></i>
                      </span>
                      <span>
                        {{
                          listData[0].photoPemasangan.path === "" ||
                          listData[0].photoPemasangan.path === undefined ||
                          listData[0].photoPemasangan.path === null
                            ? " Belum ada File Terupload"
                            : "File Terupload"
                        }}
                      </span>
                    </a>
                  </div>
                </div>
                <div class="row mb-4">
                  <div class="col-lg-2">
                    <strong>Foto Sparing</strong>
                  </div>
                  <div class="col-lg-10">
                    <a :href="listData[0].photoSPARING.path" target="blank">
                      <span>
                        <i class="ni ni-cloud-download-95 text-primary"></i>
                      </span>
                      <span>
                        {{
                          listData[0].photoSPARING.path === "" ||
                          listData[0].photoSPARING.path === undefined ||
                          listData[0].photoSPARING.path === null
                            ? " Belum ada File Terupload"
                            : "File Terupload"
                        }}
                      </span>
                    </a>
                  </div>
                </div>
                <div class="row mb-4">
                  <div class="col-lg-2">
                    <strong>Dokumen Pemasangan</strong>
                  </div>
                  <div class="col-lg-10">
                    <a :href="listData[0].filePemasangan.path" target="blank">
                      <span>
                        <i class="ni ni-cloud-download-95 text-primary"></i>
                      </span>
                      <span>
                        {{
                          listData[0].filePemasangan.path === "" ||
                          listData[0].filePemasangan.path === undefined ||
                          listData[0].filePemasangan.path === null
                            ? " Belum ada File Terupload"
                            : "File Terupload"
                        }}
                      </span>
                    </a>
                  </div>
                </div>
                <div class="row mb-4">
                  <div class="col-lg-2">
                    <strong>Perencanaan Masa Uji / Commisioning</strong>
                  </div>
                  <div class="col-lg-10">
                    <a :href="listData[0].fileCommission.path" target="blank">
                      <span>
                        <i class="ni ni-cloud-download-95 text-primary"></i>
                      </span>
                      <span>
                        {{
                          listData[0].fileCommission.path === "" ||
                          listData[0].fileCommission.path === undefined ||
                          listData[0].fileCommission.path === null
                            ? " Belum ada File Terupload"
                            : "File Terupload"
                        }}
                      </span>
                    </a>
                  </div>
                </div>
                <div class="row mb-4">
                  <div class="col-lg-2">
                    <strong>Rencana Pengoperasian Sparing</strong>
                  </div>
                  <div class="col-lg-10">
                    <a :href="listData[0].filePengoperasion.path" target="blank">
                      <span>
                        <i class="ni ni-cloud-download-95 text-primary"></i>
                      </span>
                      <span>
                        {{
                          listData[0].filePengoperasion.path === "" ||
                          listData[0].filePengoperasion.path === undefined ||
                          listData[0].filePengoperasion.path === null
                            ? " Belum ada File Terupload"
                            : "File Terupload"
                        }}
                      </span>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import RouteBreadCrumb from "@/components/Breadcrumb/RouteBreadcrumb";
import BaseHeader from "@/components/BaseHeader";
import ModalValidasi from "./ModalValidasi.vue";
import dummyPelaporanProgress from "../../Tables/dummyPelaporanProgress";

export default {
  components: {
    BaseHeader,
    RouteBreadCrumb,
    ModalValidasi
  },
  mounted() {
    let result = dummyPelaporanProgress.filter(data => data.id == this.$route.params.id)
    this.listData = result
    console.log(result);
  },
  data() {
    return {
      listData: [],
      showModal:false,
    };
  },
  methods: {
    validasiHandler() {
      console.log('call');
      this.showModal = true;
    },
    onCloseModal() {
      this.showModal = false;
      this.getData();
    },
    back() {
      this.$router.push("/verifikasi/progress-report");
    },
  },
};
</script>
<style></style>
